<template>
    <div class="BaseReport">
        <div class="page-title">基本报表</div>
        <div class="table-container">
            <a-table :columns="columns" :dataSource="tableData" :scroll="{ y: 550 }" :rowSelection="rowSelection" />
        </div>
    </div>
</template>

<script>
    const columns = [
        {
            title: '名称',
            dataIndex: 'OrgName',
            rowKey: 'key',
        },
        {
            title: 'PartLevel',
            dataIndex: 'PartLevel',
            rowKey: 'key',
        },
        {
            title: 'PartnerType',
            dataIndex: 'PartnerType',
            rowKey: 'key',
        },
    ];

    const data = [
        {
            key: 1,
            name: 'John Brown sr.',
            age: 60,
            address: 'New York No. 1 Lake Park',
            children: [
                {
                    key: 11,
                    name: 'John Brown',
                    age: 42,
                    address: 'New York No. 2 Lake Park',
                },
                {
                    key: 12,
                    name: 'John Brown jr.',
                    age: 30,
                    address: 'New York No. 3 Lake Park',
                    children: [
                        {
                            key: 121,
                            name: 'Jimmy Brown',
                            age: 16,
                            address: 'New York No. 3 Lake Park',
                        },
                    ],
                },
                {
                    key: 13,
                    name: 'Jim Green sr.',
                    age: 72,
                    address: 'London No. 1 Lake Park',
                    children: [
                        {
                            key: 131,
                            name: 'Jim Green',
                            age: 42,
                            address: 'London No. 2 Lake Park',
                            children: [
                                {
                                    key: 1311,
                                    name: 'Jim Green jr.',
                                    age: 25,
                                    address: 'London No. 3 Lake Park',
                                },
                                {
                                    key: 1312,
                                    name: 'Jimmy Green sr.',
                                    age: 18,
                                    address: 'London No. 4 Lake Park',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            key: 2,
            name: 'Joe Black',
            age: 32,
            address: 'Sidney No. 1 Lake Park',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },
    };
    export default {
        name: "BaseReport",
        data(){
            return {
                tableData: [],
                columns,
                rowSelection,
            }
        },
        created(){
            this.$axios.get(5836122,{StartDate: '2019-12-01',EndDate:'2019-12-31'},res=>{
                console.log(res)
                this.tableData = res.data;
            })
        },
    }
</script>

<style scoped lang="less">
    .BaseReport{
        height: 100%;
        background: #fff;
        .table-container{
            padding: 0 20px;
            height:calc(~'100% - 70px');
            overflow: scroll;
        }
        .page-title{
            padding: 15px 20px;
            margin-bottom: 20px;
            font-size: 18px;
            color: #666;
            border-bottom: 1px solid #ddd;
        }
    }
</style>